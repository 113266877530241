import { render, staticRenderFns } from "./TripLocationDetail.vue?vue&type=template&id=5d4cc6d8&"
import script from "./TripLocationDetail.vue?vue&type=script&lang=js&"
export * from "./TripLocationDetail.vue?vue&type=script&lang=js&"
import style0 from "./TripLocationDetail.vue?vue&type=style&index=0&lang=css&"
import style1 from "./TripLocationDetail.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VCol,VContainer,VIcon,VImg,VMain,VProgressCircular,VRow,VScaleTransition,VSheet,VSlideGroup,VSlideItem,VTextField})
