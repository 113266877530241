<template>
  <v-main style="background-color: #fff" class="mt-16">
    <v-container class="my-16">
      <v-row class="justify-space-between">
        <h4 v-if="!editing && location != null">
          {{ location.name }}
          <v-icon @click="editing = !editing">mdi-pencil</v-icon>
        </h4>
        <v-text-field
          v-else
          class="input-sm"
          v-model="locationName"
          @change="_changeItem(locationName, 'name')"
        />
      </v-row>
      <v-row class="justify-start mt-6">
        <label for="avatarUpload" class="me-4">
          <v-card
            min-width="300"
            height="100%"
            outlined
            class="mr-8 d-flex align-center justify-center"
            style="cursor: pointer"
          >
            <div
              class="text-center"
              style="font-size: 12pt; font-weight: 700"
              align="center"
            >
              <v-icon color="black">mdi-plus-circle</v-icon>
              Зураг оруулаx
              <span v-if="imageFiles">({{ imageFiles.length }})</span>
            </div>
            <input
              ref="file"
              type="file"
              id="avatarUpload"
              class="d-none drop"
              @change="uploadFile"
            />
          </v-card>
        </label>
        <v-sheet outlined elevation="0" max-width="800" max-height="300">
          <v-slide-group class="pa-0" multiple show-arrows>
            <v-slide-item v-for="(image, n) in imageFiles" :key="n">
              <v-card class="ma-4" width="100" height="100">
                <v-row class="fill-height" align="center" justify="center">
                  <v-img
                    :src="image.fileUrl"
                    :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-row>
              </v-card>
            </v-slide-item>
            <v-card
              v-if="imageFiles && imageFiles.length == 0"
              class="ma-4"
              height="100"
              width="100"
            >
              <v-row class="fill-height" align="center" justify="center">
                <v-scale-transition> </v-scale-transition>
              </v-row>
            </v-card>
          </v-slide-group>
        </v-sheet>
      </v-row>
      <v-row>
        <v-card class="mt-4 pa-0 ma-0" width="100%">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" lg="4">
                <h4
                  v-if="coords == null"
                  style="cursor: pointer"
                  class="black--text"
                  @click="_editGPS"
                >
                  Цэгийн мэдээлэл оруулаx
                </h4>
                <h4 v-if="diss == true" @click="diss = !diss">{{ coords }}</h4>
                <v-text-field
                  autofocus
                  @keyup.enter.prevent="_saveGPS"
                  id="gpsfield"
                  ref="gpsfieldref"
                  v-model="coords"
                  v-if="diss == false"
                  append-icon="mdi-close"
                  @click:append="cancel"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8" lg="8">
                <span
                  class="mr-2"
                  x-small
                  style="
                    font-size: 9pt !important;
                    cursor: pointer;
                    white-space: nowrap;
                  "
                  v-for="(aimag, aimagindex) in cities"
                  :key="aimagindex"
                  @click="_selectAimag(aimag)"
                  ><span
                    :class="
                      location.selectedCity &&
                      aimag.ref.path == location.selectedCity.path
                        ? 'white--text aimag-selected'
                        : 'black--text aimag-text'
                    "
                    >{{ aimag.name }}</span
                  ></span
                >
              </v-col>

              <!-- <v-col cols="12" md="3" lg="3">
                <v-btn
                  v-if="
                    (imageFiles && imageFiles.length > 0) ||
                    (selectedLng != null && selectedLat != null)
                  "
                  color="#000"
                  style="color: white !important"
                  @click="_save"
                >
                  Xадгалаx
                </v-btn>
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-mapbox
            access-token="pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ"
            map-style="mapbox://styles/mapbox/satellite-streets-v11"
            :center="[106.9057, 47.8864]"
            :zoom="4.5"
            id="map"
            ref="map"
          ></v-mapbox>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";
import TripCard from "./TripCard.vue";
import Vue from "vue";
import firebase from "firebase/app";
const fb = require("../../firebaseConfig.js");
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
export default {
  data: () => ({
    location: null,
    selectedCity: null,
    editing: false,
    title: "dfsdfasdf",
    uploading: false,
    uploadPercent: null,
    imageFiles: [],
    selectedLng: null,
    selectedLat: null,
    cities: null,
    locationName: null,
    lastClickedPoint: null,

    coords: null,
    diss: true,
  }),
  components: {
    TripCard,
  },
  watch: {
    selectedLat() {
      this.coords = this.selectedLng + " , " + this.selectedLat;
    },
  },
  computed: {},
  created() {
    if (this.locationId != null)
      fb.db.doc(this.locationId).onSnapshot((doc) => {
        let location = doc.data();
        location.id = doc.id;
        location.ref = doc.ref;
        this.location = location;
        this.locationName = this.location.name;
        //this.coords = this.location.gpsfield.
        this.selectedLng = this.location.gpsfield
          ? this.location.gpsfield.longitude
          : null;
        this.selectedLat = this.location.gpsfield
          ? this.location.gpsfield.latitude
          : null;

        if (this.location.images) this.imageFiles = this.location.images;
        else this.imageFiles = [];
      });
    fb.db
      .collection("countries/Mongolia/cities")
      .orderBy("index", "asc")
      .onSnapshot((querySnapshot) => {
        this.cities = [];
        querySnapshot.forEach((doc) => {
          let city = doc.data();
          city.id = doc.id;
          city.ref = doc.ref;
          this.cities.push(city);
        });
      });
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    const truckLocation = [106.9057, 47.8864];
    const warehouseLocation = [106.9057, 47.8864];
    const lastAtRestaurant = 0;
    let keepTrack = [];
    var pointHopper = {};
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ";
    let map = this.$refs.map.map;

    const warehouse = turf.featureCollection([turf.point(warehouseLocation)]);

    // Create an empty GeoJSON feature collection for drop off locations
    const dropoffs = turf.featureCollection([]);

    // Create an empty GeoJSON feature collection, which will be used as the data source for the route before users add any new data
    const nothing = turf.featureCollection([]);

    map.on("load", async () => {
      const marker = document.createElement("div");
      var _this = this;
      marker.classList = "truck";

      // Create a new marker
      new mapboxgl.Marker(marker).setLngLat(truckLocation).addTo(map);

      // Create a circle layer
      map.addLayer({
        id: "warehouse",
        type: "circle",
        source: {
          data: warehouse,
          type: "geojson",
        },
        paint: {
          "circle-radius": 20,
          "circle-color": "white",
          "circle-stroke-color": "#3887be",
          "circle-stroke-width": 3,
        },
      });

      // Create a symbol layer on top of circle layer
      map.loadImage("/home.png", (error, image) => {
        if (error) throw error;

        // Add the image to the map style.
        map.addImage("home", image);
      });

      map.addLayer({
        id: "warehouse-symbol",
        type: "symbol",
        source: {
          data: warehouse,
          type: "geojson",
        },
        layout: {
          "icon-image": "home",
          "icon-size": 0.75,
        },
        paint: {
          "text-color": "#3887be",
        },
      });

      map.addLayer({
        id: "dropoffs-symbol",
        type: "symbol",
        source: {
          data: dropoffs,
          type: "geojson",
        },
        layout: {
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-image": "marker-15",
        },
      });

      map.addSource("route", {
        type: "geojson",
        data: nothing,
      });

      map.addLayer(
        {
          id: "routeline-active",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": ["interpolate", ["linear"], ["zoom"], 12, 3, 22, 12],
          },
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: "routearrows",
          type: "symbol",
          source: "route",
          layout: {
            "symbol-placement": "line",
            "text-field": "▶",
            "text-size": ["interpolate", ["linear"], ["zoom"], 12, 24, 22, 60],
            "symbol-spacing": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              30,
              22,
              160,
            ],
            "text-keep-upright": false,
          },
          paint: {
            "text-color": "#3887be",
            "text-halo-color": "hsl(55, 11%, 96%)",
            "text-halo-width": 3,
          },
        },
        "waterway-label"
      );
      if (this.selectedLng && this.selectedLat) {
        _this.lastClickedPoint = new mapboxgl.Marker()
          .setLngLat([this.selectedLng, this.selectedLat])
          .addTo(map);
        const pt = turf.point([this.selectedLng, this.selectedLat], {
          orderTime: Date.now(),
          key: Math.random(),
        });
        pointHopper = {};
        dropoffs.features.pop();
        dropoffs.features.push(pt);
        pointHopper[pt.properties.key] = pt;

        // Make a request to the Optimization API
        const query = await fetch(assembleQueryURL(), { method: "GET" });
        const response = await query.json();

        // Create an alert for any requests that return an error
        if (response.code !== "Ok") {
          const handleMessage =
            response.code === "InvalidInput"
              ? "Refresh to start a new route. For more information: https://docs.mapbox.com/api/navigation/optimization/#optimization-api-errors"
              : "Try a different point.";
          alert(`${response.code} - ${response.message}\n\n${handleMessage}`);
          // Remove invalid point
          dropoffs.features.pop();
          delete pointHopper[pt.properties.key];
          return;
        }

        // Create a GeoJSON feature collection
        const routeGeoJSON = turf.featureCollection([
          turf.feature(response.trips[0].geometry),
        ]);

        // Update the `route` source by getting the route source
        // and setting the data equal to routeGeoJSON
        map.getSource("route").setData(routeGeoJSON);
        console.log(dropoffs);
      }
      // Listen for a click on the map
      await map.on("click", addWaypoints);
      map.on("click", function (e) {
        if (_this.lastClickedPoint != null) _this.lastClickedPoint.remove();
        _this.selectedLng = e.lngLat.lng;
        _this.selectedLat = e.lngLat.lat;

        _this.location.ref.update({
          gpsfield: new firebase.firestore.GeoPoint(e.lngLat.lat, e.lngLat.lng),
        });
        _this.lastClickedPoint = new mapboxgl.Marker()
          .setLngLat(e.lngLat)
          .addTo(map);
      });
    });

    async function addWaypoints(event) {
      // When the map is clicked, add a new drop off point
      // and update the `dropoffs-symbol` layer
      console.log("asdadasd");
      await newDropoff(map.unproject(event.point));
      updateDropoffs(dropoffs);
    }

    async function newDropoff(coordinates) {
      // Store the clicked point as a new GeoJSON feature with
      // two properties: `orderTime` and `key`
      const pt = turf.point([coordinates.lng, coordinates.lat], {
        orderTime: Date.now(),
        key: Math.random(),
      });
      pointHopper = {};
      dropoffs.features.pop();
      dropoffs.features.push(pt);
      pointHopper[pt.properties.key] = pt;

      // Make a request to the Optimization API
      const query = await fetch(assembleQueryURL(), { method: "GET" });
      const response = await query.json();

      // Create an alert for any requests that return an error
      if (response.code !== "Ok") {
        const handleMessage =
          response.code === "InvalidInput"
            ? "Refresh to start a new route. For more information: https://docs.mapbox.com/api/navigation/optimization/#optimization-api-errors"
            : "Try a different point.";
        alert(`${response.code} - ${response.message}\n\n${handleMessage}`);
        // Remove invalid point
        dropoffs.features.pop();
        delete pointHopper[pt.properties.key];
        return;
      }

      // Create a GeoJSON feature collection
      const routeGeoJSON = turf.featureCollection([
        turf.feature(response.trips[0].geometry),
      ]);

      // Update the `route` source by getting the route source
      // and setting the data equal to routeGeoJSON
      map.getSource("route").setData(routeGeoJSON);
    }

    function updateDropoffs(geojson) {
      map.getSource("dropoffs-symbol").setData(geojson);
    }

    // Here you'll specify all the parameters necessary for requesting a response from the Optimization API
    function assembleQueryURL() {
      // Store the location of the truck in a variable called coordinates
      const coordinates = [truckLocation];
      const distributions = [];
      let restaurantIndex;
      keepTrack = [truckLocation];

      // Create an array of GeoJSON feature collections for each point
      const restJobs = Object.keys(pointHopper).map((key) => pointHopper[key]);

      // If there are actually orders from this restaurant
      if (restJobs.length > 0) {
        // Check to see if the request was made after visiting the restaurant
        const needToPickUp =
          restJobs.filter((d) => d.properties.orderTime > lastAtRestaurant)
            .length > 0;

        // If the request was made after picking up from the restaurant,
        // Add the restaurant as an additional stop
        if (needToPickUp) {
          restaurantIndex = coordinates.length;
          // Add the restaurant as a coordinate
          coordinates.push(warehouseLocation);
          // push the restaurant itself into the array
          keepTrack.push(pointHopper.warehouse);
        }

        for (const job of restJobs) {
          // Add dropoff to list
          keepTrack.push(job);
          coordinates.push(job.geometry.coordinates);
          // if order not yet picked up, add a reroute
          if (needToPickUp && job.properties.orderTime > lastAtRestaurant) {
            distributions.push(`${restaurantIndex},${coordinates.length - 1}`);
          }
        }
      }

      // Set the profile to `driving`
      // Coordinates will include the current location of the truck,
      return `https://api.mapbox.com/optimized-trips/v1/mapbox/driving/${coordinates.join(
        ";"
      )}?distributions=${distributions.join(
        ";"
      )}&overview=full&steps=true&geometries=geojson&source=first&access_token=${
        mapboxgl.accessToken
      }`;
    }
  },
  methods: {
    _selectAimag(aimag) {
      this.location.ref.update({
        selectedCity: aimag.ref,
        selectedCityName: aimag.name,
        code: aimag.code,
      });
    },
    _editGPS() {
      this.diss = !this.diss;
      if (this.$refs["gpsfieldref"]&&this.$refs["gpsfieldref"].length>0) this.$refs["gpsfieldref"][0].focus();
    },
    _saveGPS() {
      console.log(this.coords.split(","));
      var xx = this.coords.split(",");
      if (xx.length == 2) {
        const geoPoint = new firebase.firestore.GeoPoint(
          Number(xx[0]),
          Number(xx[1])
        );
        this.location.ref.update({ geoPoint: geoPoint }).then(() => {
          this.diss = !this.diss;
          document.getElementById("gpsfield").blur();
        });
      }
    },
    cancel() {
      this.diss = !this.diss;
      // this.coords = this.selectedLng + " , " + this.selectedLat
      // console.log(this.coords)
    },
    async _save() {
      const geoPoint = new firebase.firestore.GeoPoint(
        Number(this.selectedLat),
        Number(this.selectedLng)
      );
      await this.location.ref
        .update({
          name: this.location.name,
          createdAt: new Date(),
          geopoint: geoPoint,
          selectedCity:
            this.selectedCity != null ? this.selectedCity.ref : null,
          selectedCityName:
            this.selectedCity != null ? this.selectedCity.name : null,
          images:
            this.imageFiles != null && this.imageFiles.length > 0
              ? this.imageFiles.map((x) => x)
              : null,
        })
        .then(() => {
          console.log("success");
        });
    },
    _changeItem(locationName, key) {
      if (key == "name") {
        this.location.name = locationName;
      }
      this.location.ref.update(this.location).then(() => {
        this.editing = !this.editing;
        console.log("success");
      });
    },
    uploadFile() {
      var _this = this;
      var files = this.$refs.file.files;

      console.log(files[0].name);
      if (files != null && files.length > 0) {
        var uploadTask = fb.storageRef.child(files[0].name).put(files[0]);
        _this.uploading = true;
        _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                _this.imageFiles.push(downloadURL);

                const fileObject = {
                  fileName: files[0].name,
                  fileUrl: downloadURL,
                  uploadedAt: new Date(),
                };
                _this.location.ref.update({
                  images: fb.firestore.FieldValue.arrayUnion(fileObject),
                });
                _this.$refs.file.value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs.file.type = "";
                  _this.$refs.file.type = "file";
                }
              });
          }
        );
      }
    },
  },
};
</script>
<style>
.mapboxgl-map {
  overflow: auto !important;
  height: 100vh;
}

.sidebar {
  position: absolute;
  margin: 20px 20px 30px 20px;
  width: 25%;
  top: 0;
  bottom: 0;
  padding: 20px;
  background-color: #fff;
  overflow-y: scroll;
}

.card {
  font-size: small;
  border-bottom: solid #d3d3d3 2px;
  margin-bottom: 6px;
}

.card-header {
  font-weight: bold;
  padding: 6px;
}

.no-route {
  background-color: #d3d3d3;
  color: #f00;
}

.obstacle-found {
  background-color: #d3d3d3;
  color: #fff;
}

.route-found {
  background-color: #33a532;
  color: #fff;
}

.card-details {
  padding: 3px 6px;
}
</style>

<style>
.mapboxgl-map {
  overflow-x: hidden;
  height: 60vh;
}
.aimag-text:hover {
  background: black !important;
  color: white !important;
  padding: 5px 5px;
}
.aimag-selected {
  background: black !important;
  color: white !important;
  padding: 5px 5px;
}
</style>
